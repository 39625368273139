import {
  Button,
  CarouselRenderProp,
  Heading,
  HeadingVariants,
  HydrateWhenInViewport,
} from '@loveholidays/design-system';
import React from 'react';

import { HolidayCard } from './HolidayCard';
import { Badge, HolidayCollectionProps } from '@AuroraTypes';
import { usePathTemplate } from '@Core/usePathTemplate';
import { getUrlFromSearchSelection } from '@Stores/getUrlFromSearchSelection';
import { useProductImpressionTracking } from '@Tracking';

export interface HolidayCollectionDisplayProps extends HolidayCollectionProps {
  maxItemsOnShow?: [number, number, number];
  titleVariant?: HeadingVariants;
  internalLink?: boolean;
}

export const getCancellationBadge = (badges?: Badge[]) =>
  badges?.find(({ type }) => type === 'FREE_CANCELLATION');

export const HolidayCollection: React.FC<HolidayCollectionDisplayProps> = ({
  title,
  ctaText,
  searchSelection,
  holidays,
  productTrackingList,
  children,
  linkTarget,
  maxItemsOnShow,
  titleVariant = 'largebold',
  internalLink = false,
}) => {
  const srpPath = usePathTemplate('srp');

  return (
    <HydrateWhenInViewport>
      <CarouselRenderProp
        sx={{
          marginX: [0, '-xs'],
          scrollPaddingLeft: [0, 'xs'],
          scrollPaddingRight: [0, 'xs'],
          paddingX: [0, 'xs'],
          paddingBottom: 'xs',
        }}
        maxItemsOnShow={maxItemsOnShow}
        itemsOnShowOverride={maxItemsOnShow ? [maxItemsOnShow] : undefined}
        render={({ carousel, scrollButtons }) => (
          <section
            sx={{ marginY: ['2xl', null, '3xl'] }}
            data-id="HolidayCollection"
          >
            <div
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 's',
                alignItems: 'baseline',
              }}
            >
              <Heading
                variant={titleVariant}
                as="h2"
              >
                {title}
              </Heading>
              {scrollButtons}
            </div>
            {carousel}
            {!!ctaText && (
              <Button
                data-id="see-all-btn"
                variant="Outline"
                size="48"
                href={`${srpPath}?${getUrlFromSearchSelection(searchSelection!)}`}
                internal
                asAnchor={false}
                sx={{
                  marginTop: 'l',
                }}
              >
                {ctaText}
              </Button>
            )}
          </section>
        )}
      >
        {holidays.filter(Boolean).map((holiday, index) => {
          const {
            hotel: { accommodation },
            badges,
          } = holiday;

          const cancellationBadge = getCancellationBadge(badges);

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const ref = useProductImpressionTracking({
            masterId: accommodation.id,
            position: index + 1,
            ...accommodation,
            list: productTrackingList,
            ...(cancellationBadge && { refundablePackage: true }),
          });

          return (
            <HolidayCard
              key={index}
              ref={ref}
              type={productTrackingList}
              linkTarget={linkTarget}
              offer={holiday}
              internalLink={internalLink && ['srp', 'hdp_modal'].includes(linkTarget)}
              searchSelection={searchSelection}
              {...accommodation}
            />
          );
        })}
        {children}
      </CarouselRenderProp>
    </HydrateWhenInViewport>
  );
};
